.about{
  flex-direction: column;
}

.about_text {
  text-align: center;
  width: 240px;
}

.about_text p{
  padding: 5px 0;
}

.WaLink {
  color: #25D366;
}

@media (min-width: 768px) {
  .about_text {
    width: 700px;
  }
  .about{
    gap: 25px;
  }
}