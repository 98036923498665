main {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  grid-area: main;
  height: 100vh;
  justify-content: center;
}

.container {
  align-items: center;
  background: var(--gradient-color);
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
    text-align: center;
    padding: 0; /* Adicione esta linha para remover qualquer padding */
  }
}
