.profileImage {
  position: relative;
}

#photo {
  position: absolute;
  transform: rotateY(0deg);
}

#photo, #cartoon {
  border-radius: 50%;
  border: 2px solid var(--ternary-color);
  height: 500px;
  width: 500px;
}

.hiddenImage {
  opacity: 0;
  transform: rotateY(-180deg);
  transition: opacity 1.5s ease-in-out;
}

.showImage {
  opacity: 1;
  transition: opacity 1.3s ease-in-out;
}

@media (max-width: 1023px) {
  #photo, #cartoon {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 700px) {
  #photo, #cartoon {
    display: none;
  }
}