.menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menu ul {
  width: 100%;
}

.menu li {
  padding: 14px 0;
  width: 100%;
}

.menu a, .icon{
  color: var(--primary-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
}

.menu a p {
  font-size: 18px;
}

.icon {
  font-size: 22px;
}

.menu a.active {
  background-color: var(--secondary-color);
  color: var(--ternary-color);
}

.menu a:hover {
  border-bottom: 2px solid var(--secondary-color);
  color: var(--secondary-color);
}

.menu a:hover .icon{
  color: var(--secondary-color);
}

.menu a.active .icon {
  color: var(--ternary-color);
}

.menu a.active p {
  color: var(--ternary-color);
}

@media (max-width: 768px) {
  .menu a p {
    font-size: 18px;
  }
  .icon {
    font-size: 18px;
  }
}