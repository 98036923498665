/* Footer.css */
footer {
  background-color: var(--primary-color);
  color: var(--ternary-color);
  padding: 10px 0;
  text-align: center;
  width: 100%;
}

.FooterExpanded ul {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.FooterExpanded li {
  display: inline;
  margin: 0 10px;
}

.FooterCollapsed li {
  display: block;
}

footer a {
  color: var(--ternary-color);
  font-size: 18px;
  text-decoration: none;
  transition: font-size 0.7s ease-in-out;
}

.FooterExpanded a {
  font-size: 24px;
}

@media (max-width: 700px) {
  .FooterExpanded a {
    font-size: 18px;
  }
}