.home_text {
  padding: 20px;
}
.home_contact {
  margin: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

h1 {
  font-size: 3em;
  color: var(--ternary-color);
  max-width: 400px;
  margin-bottom: 15px;
}

h2 {
  font-size: 2em;
  color: var(--ternary-color);
  margin-bottom: 20px;
}

p {
  font-size: 1.3em;
}

.whatsappButton, .contactButton {
  background-color: #25D366;
  color: var(--ternary-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  font-size: 1em;
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.contactButton {
  background-color: #252a53;
}

.whatsappButton:hover, .contactButton:hover {
  border: var(--ternary-color) solid 1.5px;
  background-color: #1d1c30;
}

@media (max-width: 1023px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  p {
    font-size: 1.2em;
  }

  .whatsappButton, .contactButton {
    font-size: 1em;
    margin: 5px;
  }
}

@media (max-width: 700px) {
  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1em;
  }

  p {
    font-size: 0.9em;
  }
    
  .home_contact {
    flex-direction: column;
  }

  .whatsappButton, .contactButton {
    font-size: 0.8em;
  }
}
