.carouselCards {
  cursor: grab;
  overflow: hidden;
}

.containerCards {
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border: 2px solid var(--ternary-color);
  border-radius: 6%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px;
  transition: all 0.3s ease-in-out;
}

.card:hover {
  border: 3px solid var(--secondary-color);
  border-radius: 7%;
}

@media (min-width:425px) {
  .containerCards {
    max-width: 300px;
  }
}

@media (min-width:768px) {
  .containerCards {
    max-width: 700px;
  }
}

@media (min-width:1024px) {
  .containerCards {
    max-width: 900px;
  }
}