.header {
  align-items: center;
  background-color: var(--ternary-color);
  display: flex;
  flex-direction: column;
  grid-area: header;
  height: 100vh;
  justify-content: space-between;
  padding: 20px 0;
  transition: width 0.7s ease-in-out;
  z-index: 1;
}
.header.collapsed {
  width: 50px;
}

.header.expanded {
  width: 200px;
}
.slogan {
  width: 60px;
}

.logo {
  width: 130px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {  
  .header.expanded {
    width: 150px;
  }
  .logo {
    width: 100px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) { 
  .header.expanded {
    width: 150px;
  }
  .logo {
    width: 110px;
    margin-bottom: 10px;
  }
}
