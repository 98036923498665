@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&family=Hind:wght@400;700&display=swap');

:root {
  --primary-color: #1d1c30;
  --secondary-color: #5c88fe;
  --ternary-color: #ffffff;
  --gradient-color: linear-gradient(135deg, var(--primary-color) 50%, var(--secondary-color) 100%);
  --font-primary: 'Ubuntu', sans-serif;
  --font-secondary: 'Hind', sans-serif;
}
* {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  font-family: var(--font-secondary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondary-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-primary);
}
