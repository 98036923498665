.containerSlider{
  height: 60px;
  width: 100%;
  mask-image: linear-gradient(to left,
  transparent,
  #000 5% 5%,
  transparent);
  overflow: hidden;
}

.slider {
  display: flex;
  position: relative;
  width: 100%;
}

.sliderlItens {
  animation: autoSlide 10s linear infinite;
  animation-delay: calc(10 / var(--quantity) * var(--delay));
  cursor: pointer;
  height: 50px;
  left: 100%;
  max-width: 60px;
  position: absolute;
  width: 100%;
}

.sliderlItens img {
  width: 100%;
}

.containerSlider:hover .sliderlItens{
  animation-play-state: paused;
  filter: grayscale(1);
}

.containerSlider .sliderlItens:hover {
  filter: grayscale(0);
}


@keyframes autoSlide {
  from {
    left: 100%;
  }

  to {
    left: -50px;
  }
}

@media (max-width: 768px) {
  
.sliderlItens img {
  width: 40px;
}
}