.thumbContainer {
  width: 300px;
  overflow: hidden;
  cursor: grab;
  border-radius: 8%;
}

.thumbImage {
  width: 100%;
  transition: all 0.6s linear;
}

.thumbImage:hover {
  scale: 1.4;
}